<template>
  <v-container>
    <v-row align="center">
      <v-col cols="12">
        <v-card>
          <v-dialog v-model="updateStatusDialog" persistent max-width="500px">
            <v-card>
              <v-card-title>修改申请状态/Update Application Status</v-card-title>
              <v-card-subtitle>{{ updateInfo.desc }}</v-card-subtitle>
              <v-card-text>
                <v-select v-model="infoStatus" :items="infoStatusSelect" item-value="name">
                  <template v-slot:item="{ item }">
                    <v-icon>{{ infoStatusList[item.name].icon }}</v-icon
                    >{{ item.desc }}
                  </template>
                  <template v-slot:selection="{ item }">
                    <v-icon>{{ infoStatusList[item.name].icon }}</v-icon
                    >{{ item.desc }}
                  </template>
                </v-select>
                <v-card-actions class="justify-center">
                  <v-btn type="submit" color="primary" @click="handleStatusUpdate()"> 确认 </v-btn>
                  <v-btn type="submit" color="grey" @click="closeUpdateStatus()"> 取消 </v-btn>
                </v-card-actions>
              </v-card-text>
            </v-card>
          </v-dialog>

          <v-data-table
            :headers="infoHeaders"
            :items="info"
            :search="search"
            disable-pagination
            hide-default-footer
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title> 夏令营资料/Camp Info List </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-toolbar>

              <span class="ma-2">
                <v-progress-circular
                  v-if="waiting"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <v-btn v-else @click="exportIDPhotos()">
                  <v-icon>mdi-image-move</v-icon> 导出所有证件照
                </v-btn>
              </span>

              <span class="ma-2">
                <v-progress-circular
                  v-if="waiting"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <v-btn v-else @click="exportBasicInfo()">
                  <v-icon>mdi-table-arrow-right</v-icon> 导出基本信息
                </v-btn>
              </span>
              <span class="ma-2">
                <v-dialog v-model="exportSelectDialog" persistent max-width="600px">
                  <v-card>
                    <v-card-title>选择导出资料/Select Export Content</v-card-title>
                    <v-card-text>
                      <v-autocomplete
                        v-model="selectExportContent"
                        :items="info"
                        label="选择导出资料/Select Export Content"
                        item-value="id"
                        item-text="desc"
                        return-object
                        multiple
                        chips
                        dense
                      ></v-autocomplete>
                      <v-card-actions class="justify-center">
                        <v-btn type="submit" color="primary" @click="handleExportConfirm()">
                          <v-icon>mdi-package-down</v-icon> 确认
                        </v-btn>
                        <v-btn type="submit" color="grey" @click="handleExportClose()">
                          取消
                        </v-btn>
                      </v-card-actions>
                    </v-card-text>
                  </v-card>
                </v-dialog>
                <v-progress-circular
                  v-if="waiting"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <v-btn v-else @click="exportSelect()">
                  <v-icon>mdi-checkbox-multiple-marked</v-icon> 选择导出
                </v-btn>
              </span>

              <v-row class="ma-2" dense align="center" justify="center">
                <v-col cols="2">
                  <span class="ma-2">
                    <v-progress-circular
                      v-if="waiting"
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                    <v-btn v-else @click="exportFiltered()">
                      <v-icon>mdi-checkbox-multiple-marked</v-icon> 导出筛选
                    </v-btn>
                  </span>
                </v-col>
                <v-col>
                  <v-autocomplete
                    label="筛选状态/Filter Status"
                    v-model="showStatuses"
                    multiple
                    :items="infoStatusSelect"
                    item-value="name"
                  >
                    <template v-slot:item="{ item, on, attrs }">
                      <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                        <v-list-item-action>
                          <v-checkbox :input-value="active"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            <v-icon>{{ infoStatusList[item.name].icon }}</v-icon
                            >{{ item.desc }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <template v-slot:selection="{ item }">
                      <v-chip
                        close
                        @click:close="showStatuses = showStatuses.filter((s) => s != item.name)"
                      >
                        <v-icon>{{ infoStatusList[item.name].icon }}</v-icon>
                        {{ item.desc }}
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </template>

            <template v-slot:[`item.name`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" @click="showCampDetail(item)"> mdi-eye </v-icon>
                </template>
                <span>查看详情/Detail</span>
              </v-tooltip>
              {{ item.name }}
            </template>

            <template v-slot:[`item.status`]="{ item }">
              <v-icon>{{ infoStatusList[item.status].icon }}</v-icon
              >{{ infoStatusList[item.status].desc }}
            </template>

            <template v-slot:[`item.letters`]="{ item }">
              <v-icon v-if="item.letters">mdi-check</v-icon>
              <v-icon v-else>mdi-email-remove</v-icon>
              {{ item.letters ? "已上传" : "未上传" }}
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-progress-circular
                v-if="waiting"
                indeterminate
                color="primary"
              ></v-progress-circular>
              <v-tooltip top v-else>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" @click="resendRecommendLetterRequest(item)">
                    mdi-email-send
                  </v-icon>
                </template>
                <span>重发推荐信邀请/Resend Recommend Letter Request</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" @click="updateStatus(item)"> mdi-pencil </v-icon>
                </template>
                <span>修改申请状态/Update Application Status</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>



<script>
import requests from "@/http";

export default {
  data: () => ({
    search: "",
    waiting: false,
    infoStatus: "",
    updateInfo: {},
    showStatuses: ["submitted"],
    infoStatusList: {
      saved: { desc: "已保存", icon: "mdi-content-save" },
      returned: { desc: "已退回", icon: "mdi-send" },
      submitted: { desc: "已提交", icon: "mdi-account-check" },
      passed: { desc: "已通过", icon: "mdi-flag" },
      failed: { desc: "未通过", icon: "mdi-delete-circle" },
    },
    infoStatusSelect: [
      { desc: "已保存", name: "saved" },
      { desc: "已退回", name: "returned" },
      { desc: "已提交", name: "submitted" },
      { desc: "已通过", name: "passed" },
      { desc: "未通过", name: "failed" },
    ],

    info: [],
    updateStatusDialog: false,
    exportSelectDialog: false,
    selectExportContent: [],
  }),
  created() {
    this.getAllCampInfo();
  },
  computed: {
    infoHeaders() {
      return [
        { text: "姓名/Name", value: "name" },
        { text: "分组/Group", value: "group" },
        { text: "学校/University", value: "university" },
        { text: "院系/Department", value: "department" },
        {
          text: "状态/Status",
          value: "status",
          filter: (value) => this.showStatuses.includes(value),
        },
        { text: "推荐信/Letters", value: "letters" },
        {
          text: "操作/Actions",
          sortable: false,
          value: "actions",
        },
      ];
    },
  },
  methods: {
    getAllCampInfo() {
      requests.get(`/api/admin/camp`).then((res) => {
        this.info = res.data.info;
      });
    },
    showCampDetail(item) {
      window.open(`/review/detail/${item.id}`, "_blank").focus();
      // this.$router.push(`/review/detail/${item.id}`);
    },
    resendRecommendLetterRequest(item) {
      this.waiting = true;
      requests
        .post(`/api/admin/camp/${item.id}/recommend/resend`)
        .then((res) => {
          alert(`重发成功: ${res.data.message}`);
          this.waiting = false;
        })
        .catch((err) => {
          alert(`重发推荐信邀请失败: ${err.response.status}`);
          this.waiting = false;
        });
    },
    updateStatus(item) {
      this.infoStatus = item.status;
      this.updateInfo = item;
      this.updateStatusDialog = true;
    },
    closeUpdateStatus() {
      this.updateStatusDialog = false;
      this.$nextTick(() => {
        this.infoStatus = "";
        this.updateInfo = {};
      });
    },
    handleStatusUpdate() {
      requests
        .post(`/api/admin/camp/${this.updateInfo.id}/status/${this.infoStatus}`, {})
        .then((res) => {
          alert(res.data.message);
          this.getAllCampInfo();
          this.closeUpdateStatus();
        })
        .catch((err) => {
          alert(err.response.data.detail);
        });
    },

    adminFileDownload(token) {
      const url = `/api/admin/download?token=${token}`;
      const link = document.createElement("a");
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    exportIDPhotos() {
      this.waiting = true;
      requests
        .post(`/api/admin/camp/export/photos`)
        .then((res) => {
          this.adminFileDownload(res.data.token);
          this.waiting = false;
        })
        .catch((err) => {
          this.waiting = false;
          alert(`导出失败: ${err.response.status}`);
        });
    },
    exportBasicInfo() {
      this.waiting = true;
      requests
        .post(`/api/admin/camp/export/basic`)
        .then((res) => {
          this.adminFileDownload(res.data.token);
          this.waiting = false;
        })
        .catch((err) => {
          this.waiting = false;
          alert(`导出失败: ${err.response.status}`);
        });
    },
    exportFiltered() {
      this.waiting = true;
      var ids = [];
      this.info.forEach((s) => {
        if (this.showStatuses.includes(s.status)) {
          ids.push(s.id);
        }
      });
      this.requestSelectedExport(ids);
    },
    exportSelect() {
      this.exportSelectDialog = true;
    },
    handleExportConfirm() {
      this.waiting = true;
      var ids = [];
      this.selectExportContent.forEach((s) => {
        ids.push(s.id);
      });
      this.requestSelectedExport(ids);
    },
    handleExportClose() {
      this.exportSelectDialog = false;
      this.$nextTick(() => {
        this.selectExportContent = [];
      });
    },
    requestSelectedExport(ids) {
      requests
        .post(`/api/admin/camp/export/select`, { ids: ids })
        .then((res) => {
          this.adminFileDownload(res.data.token);
          this.waiting = false;
        })
        .catch((err) => {
          this.waiting = false;
          alert(`导出失败: ${err.response.status}`);
        });
    },
  },
};
</script>
